import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerUpper}>
        <div className={styles.logoSocial}>
          <div className={styles.logo}>
            <img
              src="images/8. Footer Logo Risk-IO.png"
              alt="Risk-IO Logo"
              className={styles.riskLogo}
            />
            <span className={styles.poweredBy}>POWERED BY</span>
            <a href="https://www.zuluconsultingroup.com">
              <img
                src="images/8. Footer Logo Zulu.png"
                alt="Zulu Logo"
                className={styles.zuluLogo}
              />
            </a>
            <img
              src="images/8. Footer Certification ISO_9001_2015 _QMS_100dpi 2.png"
              alt="ISO Logo"
            />
          </div>

          <div className={styles.contactsSocial}>
            <span>
              <img src="images/Icon Phone.svg" alt="Phone" />{" "}
              <a
                href="mailto:info@zuluconsultingroup.com"
                className={styles.mailTel}
              >
                info@zuluconsultingroup.com
              </a>
            </span>
            {/*<span>
              <a href="tel:+393487988236" className={styles.mailTel}>
                <img src="images/Icon mail.svg" alt="Mail" /> +39 348 798 8236
              </a>
            </span>*/}
            <div className={styles.social}>
              <a href="#">
                <img src="images/Icon Fb.svg" alt="FB" />
              </a>
              <a href="https://www.linkedin.com/showcase/risk-io/posts/?feedView=all">
                <img src="images/Icon Linkedin.svg" alt="Linkedin" />
              </a>
              <a href="#">
                <img src="images/Icon You Tube.svg" alt="YouTube" />
              </a>
              <a href="#">
                <img src="images/Icon X.svg" alt="X" />
              </a>
            </div>
          </div>
        </div>

        <div className={styles.rss}>
          <span className={styles.subNews}>Subscribe to our news</span>

          <div className={styles.inputContainer}>
            <input type="text" className={styles.subscribeInput} />
            <button className={styles.subscribeButton}>SUBMIT</button>
          </div>

          <span className={styles.acceptPrivacy}>
            By subscribing you agree to our Privacy Policy
          </span>
        </div>
      </div>

      <hr className={styles.divider} />

      <div className={styles.creditSection}>
        <div className={styles.links}>
          <a
            href="https://www.iubenda.com/privacy-policy/33876700"
            class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
            title="Privacy Policy "
          >
            Privacy Policy
          </a>
          {/*<a href="#">
            <span>Terms of Service</span>
          </a>*/}
          <a
            href="https://www.iubenda.com/privacy-policy/33876700/cookie-policy"
            class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
            title="Cookie Policy "
          >
            Cookie Policy
          </a>
        </div>
        <span>© 2024 Powered by Zulu Consulting Ltd</span>
      </div>
    </footer>
  );
};

export default Footer;
